document.addEventListener('turbolinks:load', function(){
  
    tinyMCE.baseURL = '/assets/tinymce';
    tinyMCE.init({
      selector: 'textarea.tinymce',
      images_upload_url: '/images.json',
      valid_elements: '*[*]',
      relative_urls: false,
      //remove_script_host: false,
      plugins: [
        'link'
      ],
      toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link | print preview media fullpage | ' +
        'forecolor backcolor emoticons | help',
      menu: {
        favs: {title: '♥', items: 'code visualaid | searchreplace | emoticons'},
        chat: { title: 'Insertions d\'élements', items: 'simple complexe' } // Menu personnalisé pour les insertions charte graphique
      },
      menubar: 'favs file edit view insert format tools table help chat',
      setup: function (editor)
        {
          editor.ui.registry.addNestedMenuItem('simple',
            {
              text: 'Simples',
              getSubmenuItems: function () {
                return [
                  
                  {
                    type: 'menuitem',
                    text: 'Texte sur deux colonnes',
                    onAction: function () {
                      editor.insertContent(TEXTE_DEUX_COLONNES);
                    }
                  },
  
                  {
                    type: 'menuitem',
                    text: 'Contenu sur deux colonnes',
                    onAction: function () {
                      editor.insertContent(CONTENU_DEUX_COLONNES);
                    }
                  },
                  {
                    type: 'menuitem',
                    text: 'Bouton',
                    onAction: function () {
                      document.ouvrir_modal("#modal_selection_couleur_bouton", function(elem)
                        {
                        url = prompt("Saissiez l'URL vers laquelle le bouton redirigera")
                        editor.insertContent(BOUTON.replace("IIAS_TAG:url:", url).replace(/IIAS_TAG\:couleur\:/g, $(elem).attr("data-val")));
                        });
                    }
                  }
                ];
              },
            });
        },
      init_instance_callback: function()
      {
        // Permet d'avoir les mêmes style dans l'editeur que sur la page
        var x = document.getElementsByTagName("iframe")[0];
        var y = (x.contentWindow || x.contentDocument);
        if (y.document)y = y.document;
  
        var elems = document.getElementsByTagName("link")
  
        for(var i = 0; i < elems.length; i++)
          {
          var node = document.createElement("link");
          node.setAttribute("rel", "stylesheet");
          node.setAttribute("type", "text/css");
          node.setAttribute("href", elems[i].getAttribute("href"));
          y.head.appendChild(node);
          }
      },
      file_picker_callback: function(callback, value, meta)
        {
          // Trigger click on file element
          jQuery("#fileupload").trigger("click");
          $("#fileupload").unbind('change');
          // File selection
          jQuery("#fileupload").on("change", function()
            {
             var file = this.files[0];
             var reader = new FileReader();
  
             // FormData
             var fd = new FormData();
             var files = file;
             fd.append("file",files);
             fd.append('filetype',meta.filetype);
  
             var filename = "";
  
              // AJAX
             jQuery.ajax({
                url: "/fichiers",
                type: "post",
                data: fd,
                contentType: false,
                processData: false,
                async: false,
                success: function(response){
                      callback(response["location"]);
                      }
                   });
  
           });
        }
    });
  });
  
  
  
  
  
  
  
  
  
  // CODE DES ELEMENTS HTML

  TEXTE_DEUX_COLONNES = `
  <div class="texte_de_presentation">Suscipit repellendus debitis molestiae fugiat et. Sapiente deserunt distinctio eos unde hic adipisci voluptatibus. Sint aliquid tempora architecto est. Impedit eius aut magni aspernatur ratione consequatur architecto iure. Facilis rem a eos.
  <p>Est illo dolorem voluptatem molestiae omnis praesentium enim delectus. Aut iure optio nihil quia voluptatem nesciunt. Rerum sed atque saepe hic sit aut accusamus. Et sint laboriosam quo eius commodi vitae.</p>
  </div>
  `
  
  CONTENU_DEUX_COLONNES = `
  <div class="en_colonne">
  <div class="demi">Suscipit repellendus debitis molestiae fugiat et. Sapiente deserunt distinctio eos unde hic adipisci voluptatibus. Sint aliquid tempora architecto est. Impedit eius aut magni aspernatur ratione consequatur architecto iure. Facilis rem a eos.</div>
  <div class="demi">Est illo dolorem voluptatem molestiae omnis praesentium enim delectus. Aut iure optio nihil quia voluptatem nesciunt. Rerum sed atque saepe hic sit aut accusamus. Et sint laboriosam quo eius commodi vitae.</div>
  </div>
  `
  
  BOUTON = `
  <a href="IIAS_TAG:url:"><button class="IIAS_TAG:couleur:">Texte du bouton</button></a>
  `