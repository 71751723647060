document.addEventListener('turbolinks:load', function(){
    document.querySelector("#burger_button").onclick = function()
        {
        let menu = document.querySelector("#menu");
        if(menu.getAttribute("data-open") == "true") menu.setAttribute("data-open", "false");
        else menu.setAttribute("data-open", "true");
        }
  });
  
  
  
  
