document.addEventListener('turbolinks:load', function(){
    let switchs = document.querySelectorAll(".switch");
    for(let i = 0; i < switchs.length; i++)
        {
        switchs[i].onclick = function()
            {
            let value = this.getAttribute("value") == "false";
            this.setAttribute("value", value);

            if(this.getAttribute("data-mode") == "hidding")
                {
                let elements = document.querySelectorAll(this.getAttribute("data-target"));
                for(let j = 0; j < elements.length; j++)
                    {
                    elements[j].style.display = value ? "block" : "none";
                    }
                }

            if(switchs[i].getAttribute("data-save") == "true")
                {
                localStorage.setItem('switch_'+switchs[i].getAttribute("name"), value);
                }
            }

        if(switchs[i].getAttribute("data-save") == "true")
            {
            let value = localStorage.getItem('switch_'+switchs[i].getAttribute("name"));
            switchs[i].setAttribute("value", value);
            switchs[i].click();
            switchs[i].click();
            }
        }
  });
  
  
  
  
