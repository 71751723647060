document.addEventListener('turbolinks:load', function(){
    var swiper = new Swiper(".mySwiper", {
        effect: "cards",
        grabCursor: true,
        slidesPerView: 'auto',
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
          },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
          }
      });
  });
  
  
